//import { createRouter,createWebHashHistory } from 'vue-router';

import { createRouter,createWebHistory } from 'vue-router';
import axios from 'axios';
import { API_URL } from '../apis/api.js';


const routes = [
 
      {
        path: "/",
        name: "/login",
        component: () => import("@/views/pages/login.vue"), 
      },
      {
        path: "/dashboard",
        name: "/dashboard",
        component: () => import("@/views/pages/dashboard.vue"),
        meta: { requiresAuth: true },    
      },
      {
        path: "/managers",
        name: "/managers",
        component: () => import("@/views/pages/managers/listManager.vue"),
        meta: { requiresAuth: true },       
      },
      {
        path: "/addManager",
        name: "/AddManager",
        component: () => import("@/views/pages/managers/addManager.vue"),
        meta: { requiresAuth: true },       
      },
      {
        path: "/editManager/:id",
        name: "/EditManager",
        component: () => import("@/views/pages/managers/editManager.vue"),
        meta: { requiresAuth: true },       
        props: true,
      },
      {
        path: "/services",
        name: "/services",
        component: () => import("@/views/pages/services/listService.vue"),
        meta: { requiresAuth: true },       
      },
      {
        path: "/addService",
        name: "/addService",
        component: () => import("@/views/pages/services/addService.vue"),
        meta: { requiresAuth: true },       
      },
      {
        path: "/editService/:id",
        name: "/EditService",
        component: () => import("@/views/pages/services/editService.vue"),
        meta: { requiresAuth: true },     
        props: true,     
      },
      {
        path: "/detailService/:id",
        name: "/DetailService",
        component: () => import("@/views/pages/services/detailService.vue"),
        meta: { requiresAuth: true },     
        props: true,     
      },
      {
        path: "/clients",
        name: "/clients",
        component: () => import("@/views/pages/clients/listClient.vue"),
        meta: { requiresAuth: true },       
      },
    
      {
        path: "/categories",
        name: "/categories",
        component: () => import("@/views/pages/categories/listCategorie.vue"),
        meta: { requiresAuth: true },       
      },
      {
        path: "/providers",
        name: "/providers",
        component: () => import("@/views/pages/providers/listProvider.vue"),
        meta: { requiresAuth: true },       
      },
      {
        path: "/editProvider/:id",
        name: "/EditProvider",
        component: () => import("@/views/pages/providers/editProvider.vue"),
        meta: { requiresAuth: true },       
        props: true,
      },
      {
        path: "/addProvider",
        name: "/AddProvider",
        component: () => import("@/views/pages/providers/addProvider.vue"),
        meta: { requiresAuth: true },       
      },
      {
        path: "/plans",
        name: "/plans",
        component: () => import("@/views/pages/plans/listPlan.vue"),
        meta: { requiresAuth: true },       
      },
      {
        path: "/cities",
        name: "/cities",
        component: () => import("@/views/pages/cities/listCity.vue"),
        meta: { requiresAuth: true },       
      },
      {
        path: "/subcategories",
        name: "/subcategories",
        component: () => import("@/views/pages/subcategories/listSubCategories.vue"),
        meta: { requiresAuth: true },       
      },
      {
        path: "/bookings",
        name: "/bookings",
        component: () => import("@/views/pages/bookings/listBooking.vue"),
        meta: { requiresAuth: true },       
      },
      {
        path: "/tickets",
        name: "/tickets",
        component: () => import("@/views/pages/tickets/listTicket.vue"),
        meta: { requiresAuth: true },       
      },
      {
        path: "/editTicket/:id",
        name: "/EditTicket",
        component: () => import("@/views/pages/tickets/editTicket.vue"),
        meta: { requiresAuth: true },       
        props: true,
      },

      {
        path: "/subscribes",
        name: "/subscribes",
        component: () => import("@/views/pages/subscribes/listSubscribe.vue"),
        meta: { requiresAuth: true },       
      },

      {
        path: "/addSubscribe",
        name: "/addSubscribe",
        component: () => import("@/views/pages/subscribes/addSubscribe.vue"),
        meta: { requiresAuth: true },       
      },

      {
        path: "/editSubscribe/:id",
        name: "/EditSubscribe",
        component: () => import("@/views/pages/subscribes/editSubscribe.vue"),
        meta: { requiresAuth: true },     
        props: true,   
      },


      {
        path: "/editProfile",
        name: "/EditProfile",
        component: () => import("@/views/pages/profile.vue"),
        meta: { requiresAuth: true },       
      },

      {
        path: '/editClient/:id',
        name: 'EditClient',
        component: () => import('@/views/pages/clients/EditClient.vue'),
        meta: { requiresAuth: true },  
        props: true
      }
      ,
      {
        path: "/messangerChat",
        name: "/messangerChat",
        component: () => import("@/views/pages/messanger/messangerChat.vue"),
        meta: { requiresAuth: true },       
      },

];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

// Auth guard with token validation
router.beforeEach(async (to, from, next) => {
  const token = localStorage.getItem('token');
  
  if (to.matched.some(record => record.meta.requiresAuth)) {
    if (!token) {
      next({ name: '/login' }); // Redirect if no token is found
    } else {
      try {
        // Send a request to validate the token
        const response = await axios.get(`${API_URL}/auth/check`, {
          headers: {
            Authorization: `Bearer ${token}`
          }
        });
        if (response.data.valid) {
          next(); // Token is valid, proceed to the route
        } else {
          // If token is invalid, remove it and redirect to login
          localStorage.removeItem('token');
          next({ name: '/login' });
        }
      } catch (error) {
        // Token validation failed (e.g., server error, invalid token)
        console.error('Token validation error:', error);
        localStorage.removeItem('token');
        next({ name: '/login' });
      }
    }
  } else {
    next(); // Route doesn't require authentication, proceed
  }
});

export default router;