import { BootstrapVue3 } from 'bootstrap-vue-3'
import { createApp } from 'vue'
import App from './App.vue'
import router from './router/routes';



import "./assets/scss/app.scss";

const app = createApp(App)

app.use(BootstrapVue3)
app.use(router)
app.mount('#app')


  